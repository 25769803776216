@import 'styles/index.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: 'Overpass', sans-serif;

  strong {
    font-weight: bold !important;
  }

  em {
    font-style: italic;
  }

  button {
    font-family: 'Overpass', sans-serif;
    cursor: pointer;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
