@font-face {
    font-family: 'Overpass';
    src: url('../resources/fonts/Overpass/Overpass-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Overpass';
    src: url('../resources/fonts/Overpass/Overpass-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Overpass';
    src: url('../resources/fonts/Overpass/Overpass-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Overpass';
    src: url('../resources/fonts/Overpass/Overpass-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}



@font-face {
    font-family: 'Anton';
    src: url('../resources/fonts/Anton/Anton-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Overpass';
    src: url('../resources/fonts/Overpass/Overpass-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Overpass';
    src: url('../resources/fonts/Overpass/Overpass-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Overpass';
    src: url('../resources/fonts/Overpass/Overpass-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Overpass';
    src: url('../resources/fonts/Overpass/Overpass-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}


@font-face {
    font-family: 'Overpass';
    src: url('../resources/fonts/Overpass/Overpass-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Overpass';
    src: url('../resources/fonts/Overpass/Overpass-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Overpass';
    src: url('../resources/fonts/Overpass/Overpass-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Overpass';
    src: url('../resources/fonts/Overpass/Overpass-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'DAMN';
    src: url('../resources/fonts/DAMN/DAMN-MOV.otf') format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lora';
    src: url('../resources/fonts/Lora/Lora.ttf') format("opentype");
    font-weight: 400;
    font-style: normal;
}