.visually-hidden {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

// SF LiveChat styles
.embeddedServiceHelpButton > .helpButton > button.helpButtonEnabled.uiButton,
.embeddedServiceSidebar > button.helpButton.embeddedServiceSidebarMinimizedDefaultUI {
    font-family: 'Overpass';
    text-transform: none;
    letter-spacing: 0;
    min-height: auto;

    &.minimizedContainer {
        background-color: rgb(66, 77, 87);
        border-color: rgb(66, 77, 87);
    }
    
    @media screen and (max-width: 767px) {
        min-width: auto;
        .embeddedServiceIcon {
            margin-right: 0;
            display: inline-block !important;
        }
        .helpButtonLabel, .minimizedText {
            display: none;
        }
    }
}

